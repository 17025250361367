import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Tab, Tabs } from '@mui/material';

import Layout from '../../Layout/Layout';
import Guard from '../../shared/Guard/Guard';
import { Role } from '../../../data';
import TabPanel from '../../ui/mui/TabPanel/TabPanel';
import { useMatch, useNavigate } from 'react-router';
import { useAuth } from '../../AuthProvider';
import EntityConfig from './EntityConfig';
import FillData from './FillData';
// import ProductCatalogUpload from '../Upload/ProductCatalog/ProductCatalogUpload';
// import UiConfiguration from './UiConfiguration';

type TabType = {
    value: string;
    label: string;
    role: string;
};

// const entityTabs: TabType[] = [
//     {
//         value: 'entityConfig',
//         label: 'Entity Config',
//         role: Role.ForecastDeliveriesManager,
//     },
//     {
//         value: 'fillData',
//         label: 'Fill Data',
//         role: Role.ForecastDeliveriesManager,
//     },
// ];

interface GuardedTabPanelProps {
    value: string;
    index: string;
    children: any;
}

// const GuardedTabPanel = ({ value, index, children }: GuardedTabPanelProps) => {
//     return (
//         <TabPanel value={value} index={index}>
//             <Guard
//                 role={entityTabs.find((tab) => tab.value === index)?.role || ''}
//                 message
//             >
//                 {children}
//             </Guard>
//         </TabPanel>
//     );
// };

export default function ForecastDeliveriesManager() {
    const navigate = useNavigate();
    const { hasRole } = useAuth();
    const match = useMatch('/admin/forecast-deliveries/:tab/*');
    const matchedTab: string = match?.params.tab || 'forecastDeliveries';

    const handleChangeTab = (event: React.SyntheticEvent, newTab: any) => {
        navigate({
            pathname: `/admin/forecast-deliveries/${newTab}`,
        });
    };

    return (
        <Layout noBreadcrumbs>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ p: 2 }}
            >
                <Typography>Admin</Typography>
                <Typography>Forecast & Deliveries</Typography>,
            </Breadcrumbs>
            <Divider />
            <Guard role={Role.ForecastDeliveriesUser} message>
                {/* <Tabs value={matchedTab} onChange={handleChangeTab} variant="fullWidth">
                    {entityTabs.map(
                        (tab) =>
                            hasRole(tab.role) && (
                                <Tab key={tab.value} value={tab.value} label={tab.label} />
                            ),
                    )}
                </Tabs>
                <Divider /> */}
                {/* <GuardedTabPanel value={matchedTab} index="entityConfig">
                    <EntityConfig />
                </GuardedTabPanel>
                <GuardedTabPanel value={matchedTab} index="fillData"> */}
                    <FillData />
                {/* </GuardedTabPanel> */}
            </Guard>
        </Layout>
    );
}
