import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import chatbotLogo from '../../../images/sustainabilityChatbot/Sustainability.png';
export default function HeaderLogo({
  title,
  height = 'calc(100vh - 170px)',
  logo = chatbotLogo,
}: {
  title: string;
  height?: string;
  logo?: string;
}) {
  return (
    <Box sx={{ mt: '0' }}>
      <Box
        sx={{
          // mt: -2.2,
          // mb: 2,
          width: 'auto',
          position: 'relative',
          top: (theme: any) =>
            theme.components?.MuiAppBar?.styleOverrides?.root?.height,
          borderRadius: 0,
          height: height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundColor: "red"
        }}
      >
        <Stack
          direction="column"
          spacing={0}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ p: 1, mt: 0 }}
        >
          <img
            // src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/logos/${title}`}
            src={logo}
            style={{
              width: 170,
              height: 'auto',
            }}
          />
          <Typography variant="h6" align="center">
            {title}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
