import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertColor, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { SyntheticEvent, useEffect, useState } from "react";
import UploadForm from "../Upload/UploadForm/UploadForm";
import RecordFilterSelect from "../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect";
import apiClient from "../../../services/apiClient";
import React from "react";
import { useAuth } from "../../AuthProvider";
import Guard from "../../shared/Guard/Guard";
import { Role } from "../../../data";
import ConfirmDialog from "../../ui/mui/ConfirmDialog/ConfirmDialog";


interface filterType {
    natcos: [];
}


export default function InputData() {
    const { profile } = useAuth();
    const [rows, setRows] = useState<any[]>([]);
    const [rowsGlobal, setRowsGlobal] = useState<any[]>([]);
    const [rowsDataHubProducts, setRowsDatahubProducts] = useState<any[]>([]);

    const [rowsDatahubQuantities, setRowsDatahubQuantities] = useState<any[]>([]);

    const [records, setRecords] = useState<any>([]);
    const [productDataTypes, setProductDataTypes] = useState<any>([]);


    const [expanded, setExpanded] = useState<string | false>(false);
    const [saveClicked, setSaveClicked] = useState<boolean>(false);
    const [updateGrid, setUpdateGrid] = useState<boolean>(false);


    const [selectedFiltersEdit, setSelectedFiltersEdit] = useState<filterType>({
        natcos: [],
    });
    const [associatedNatcosEdit, setAssociatedNatcosEdit] = useState([]);
    const [natcoRules, setNatcoRules] = useState([]);

    const [selectedNatco, setSelectedNatco] = useState(profile?.isCustomer ? profile.natco.code : '');
    const [isAll, setAll] = useState<boolean>(false);
    const [dataHubCleaned, setDatahubCleaned] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState<any>(null);

    const [alert, setAlert] = useState<{
        severity: AlertColor | undefined;
        message: string;
    }>();

    const section = {
        rules: 'Define Rules',
        global: 'Upload Global Data',
        natco: 'Upload Data By Natco',
        datahub: 'DataHub interactions'
    };


    useEffect(() => {
        apiClient.get("/natcoData", { params: { natcoCode: selectedNatco } })
            .then((response) => {
                console.log("response", response.data.records);
                setRows(response.data.records.map((item: any) => ({
                    ...item,
                    id: item.id,
                    name: item.productDataItem.name,
                    type: item.productDataType.name,

                })));
            })
            .catch((e) => {
                console.log(e);
            });


    }, [selectedNatco, saveClicked])

    useEffect(() => {
        apiClient.get("/natcoData", {})
            .then((response) => {
                console.log("response", response.data.records);
                setRowsGlobal(response.data.records.map((item: any) => ({
                    ...item,
                    id: item.id,
                    name: item.productDataItem.name,
                    type: item.productDataType.name,
                    natco: item.productDataItem.natco.code,

                })));
            })
            .catch((e) => {
                console.log(e);
            });


    }, [saveClicked, updateGrid])

    useEffect(() => {
        apiClient
            .get(`/natcos`)
            .then((response) => {
                setRecords(
                    response.data.records.map((record: any) => ({
                        id: record.id,
                        name: record.code,
                    })),
                );

            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [])

    useEffect(() => {
        apiClient
            .get(`/productDataType`)
            .then((response) => {
                setProductDataTypes(
                    response.data.records
                );
                console.log(">>productDataTypes", productDataTypes);
            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [])

    useEffect(() => {
        apiClient
            .get(`/natcoData/getDatahubProducts`)
            .then((response) => {
                console.log("products datahub", response.data);
                setRowsDatahubProducts(response.data);
            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [dataHubCleaned])

    useEffect(() => {
        apiClient
            .get(`/natcoData/getDatahubQuantities`)
            .then((response) => {
                console.log("quantities datahub", response.data);
                setRowsDatahubQuantities(response.data);
            })
            .catch((error) => {
                //TODO: handle error response
                console.log(error);
            });
    }, [dataHubCleaned])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
        },
        {
            field: 'year',
            headerName: 'Year',
            width: 100,
            editable: true,
        },
        {
            field: 'type',
            headerName: 'Plan Item',
            width: 150,
            editable: true,
        },
        {
            field: 'jan',
            headerName: 'Jan',
            width: 100,
            editable: true,
        },
        {
            field: 'feb',
            headerName: 'Feb',
            width: 100,
            editable: true,
        },
        {
            field: 'mar',
            headerName: 'Mar',
            width: 100,
            editable: true,
        },
        {
            field: 'apr',
            headerName: 'Apr',
            width: 100,
            editable: true,
        },
        {
            field: 'may',
            headerName: 'May',
            width: 100,
            editable: true,
        },
        {
            field: 'jun',
            headerName: 'Jun',
            width: 100,
            editable: true,
        },
        {
            field: 'jul',
            headerName: 'Jul',
            width: 100,
            editable: true,
        },
        {
            field: 'aug',
            headerName: 'Aug',
            width: 100,
            editable: true,
        },
        {
            field: 'sep',
            headerName: 'Sep',
            width: 100,
            editable: true,
        },
        {
            field: 'oct',
            headerName: 'Oct',
            width: 100,
            editable: true,
        },
        {
            field: 'nov',
            headerName: 'Nov',
            width: 100,
            editable: true,
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            editable: true,
        },

    ]

    const columnsGlobal: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
        },
        {
            field: 'year',
            headerName: 'Year',
            width: 100,
            editable: true,
        },
        {
            field: 'natco',
            headerName: 'Natco',
            width: 100,
            editable: true,
        },
        {
            field: 'type',
            headerName: 'Plan Item',
            width: 150,
            editable: true,
        },
        {
            field: 'jan',
            headerName: 'Jan',
            width: 100,
            editable: true,
        },
        {
            field: 'feb',
            headerName: 'Feb',
            width: 100,
            editable: true,
        },
        {
            field: 'mar',
            headerName: 'Mar',
            width: 100,
            editable: true,
        },
        {
            field: 'apr',
            headerName: 'Apr',
            width: 100,
            editable: true,
        },
        {
            field: 'may',
            headerName: 'May',
            width: 100,
            editable: true,
        },
        {
            field: 'jun',
            headerName: 'Jun',
            width: 100,
            editable: true,
        },
        {
            field: 'jul',
            headerName: 'Jul',
            width: 100,
            editable: true,
        },
        {
            field: 'aug',
            headerName: 'Aug',
            width: 100,
            editable: true,
        },
        {
            field: 'sep',
            headerName: 'Sep',
            width: 100,
            editable: true,
        },
        {
            field: 'oct',
            headerName: 'Oct',
            width: 100,
            editable: true,
        },
        {
            field: 'nov',
            headerName: 'Nov',
            width: 100,
            editable: true,
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            editable: true,
        },

    ]


    const columnsDataHubProducts: GridColDef[] = [
        {
            field: 'ProductId',
            headerName: 'ProductId',
            width: 150,
            editable: true,
        },
        {
            field: 'Name',
            headerName: 'Name',
            width: 100,
            editable: true,
        },
        {
            field: 'SupplierId',
            headerName: 'SupplierId',
            width: 150,
            editable: true,
        },
        {
            field: 'SubCategoryId',
            headerName: 'SubCategoryId',
            width: 100,
            editable: true,
        },
        {
            field: 'LocalMaterialCode',
            headerName: 'Local Material Code',
            width: 100,
            editable: true,
        },
        {
            field: 'UniqueIdentifier',
            headerName: 'Unique Identifier',
            width: 100,
            editable: true,
        },
        {
            field: 'NatcoId',
            headerName: 'Natco Id',
            width: 100,
            editable: true,
        }


    ]

    const columnsDataHubQuantities: GridColDef[] = [
        {
            field: 'ProductId',
            headerName: 'Product Id',
            width: 150,
            editable: true,
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 100,
            editable: true,
        },
        {
            field: 'Date',
            headerName: 'Date',
            width: 150,
            editable: true,
        },
        {
            field: 'Quantity',
            headerName: 'Quantity',
            width: 100,
            editable: true,
        },
        {
            field: 'QuantityId',
            headerName: 'Quantity Id',
            width: 100,
            editable: true,
        },
        {
            field: 'Comment',
            headerName: 'Comment',
            width: 100,
            editable: true,
        }

    ]

    const handleRecordChangeEdit = (
        source: any,
        checked: any,
        updatedRecords: any,
        id: any,
        label: any,
        replaceAll: any, // Flag to indicate whether to replace or add/remove items
    ) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
        console.log("****replaceALL", replaceAll);
        if (replaceAll) {
            setAll(true);
            // Replace the entire selection
            newFiltersEdit[source] = updatedRecords;
        } else {
            setAll(false);
            if (checked) {
                newFiltersEdit[source] = [...(newFiltersEdit[source] || []), updatedRecords];
            } else {
                newFiltersEdit[source] = newFiltersEdit[source].filter(
                    (item: any) => item.id !== updatedRecords.id,
                );
            }


            // // Add or remove individual records
            // updatedRecords.forEach((record: any) => {
            //     if (!newFiltersEdit[source].some((item: any) => item.id === record.id)) {
            //         newFiltersEdit[source] = [...(newFiltersEdit[source] || []), record];
            //     } else {
            //         newFiltersEdit[source] = newFiltersEdit[source].filter(
            //             (item: any) => item.id !== record.id,
            //         );
            //     }
            // });
        }

        setSelectedFiltersEdit(newFiltersEdit);
    };


    const handleFilterCancelEdit = (source: string, id: any) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
        newFiltersEdit.associatedNatcosEdit = associatedNatcosEdit;
        setSelectedFiltersEdit(newFiltersEdit);
    };

    const handleFilterApplyEdit = (source: any, id: any) => {
        const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
        setAssociatedNatcosEdit(newFiltersEdit.natcos);
        setNatcoRules(newFiltersEdit.natcos);
        console.log("selected natcos", newFiltersEdit.natcos);
        // apiClient
        //   .post(`/productCatalog`, {
        //     id: id,
        //     subcategories: selectedFiltersEdit.subcategories,
        //   })
        //   .then((res: any) => {
        //     setPcSubcategories(res.data.subcategories);
        //   })
        //   .catch((err: any) => {
        //     console.log(err);
        //   });


        // setSelectedFiltersEdit({
        //     natcos: [],
        // });
        // setAssociatedNatcosEdit([]);
    };
    const handleCancelUpdate = () => {
        setPendingUpdate(null); // Clear the pending update
        setOpenConfirm(false); // Close the dialog
        setUpdateGrid(!updateGrid);
    };
    const handleConfirmUpdate = () => {
        if (pendingUpdate) {
            if (!pendingUpdate) return null; // Handle null or undefined gracefully.

            const months = [
                'jan', 'feb', 'mar', 'apr', 'may',
                'jun', 'jul', 'aug', 'sep', 'oct',
                'nov', 'dec'
            ];

            const newProductDataValue = { ...pendingUpdate };

            months.forEach((month) => {
                const value = pendingUpdate[month];
                newProductDataValue[month] = value !== null && value !== undefined ? Number(value) : null;
            });

            newProductDataValue.isUpdated = 'Y';
            const totalValue = pendingUpdate.total;
            newProductDataValue.total = totalValue !== null && totalValue !== undefined ? Number(totalValue) : null;

            apiClient
                .post(`natcoData`, [
                    newProductDataValue
                ])
                .then(() => {
                    setUpdateGrid(!updateGrid);
                })

                .catch((err: any) => {
                    console.log(err);
                });
            return newProductDataValue;

        }
        setPendingUpdate(null); // Clear the pending update
        setOpenConfirm(false); // Close the dialog
    };


    const processRowUpdate = (productDataValue: any, oldRow: any) => {
        setPendingUpdate(productDataValue); // Save the pending update
        setOpenConfirm(true); // Open the confirmation dialog
        return productDataValue; // Return the new row to show the change in UI temporarily
    };

    const handleDownload = async () => {

        apiClient
            .get('/natcoData/download-excel', {
                params: { natcoCode: selectedNatco }, // Pass natcoCode as parameter
                responseType: 'blob', // Important for downloading files
            })
            .then((response) => {
                // Create a blob URL for the file and initiate download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data.xlsx'); // Set file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });

    };
    const handleChangePanel =
        (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };



    const currentYear = new Date().getFullYear();

    const years = Array.from({ length: 6 }, (_, i) => currentYear - 2 + i); // Two years before, current year, three years after
    const types = productDataTypes.map((t: any) => t.name); // Three types
    // Initialize the checked state with no years checked
    const [checkedState, setCheckedState] = React.useState(
        Array.from({ length: types.length || 3 }, () =>
            Array.from({ length: years.length }, () => false) // Set all cells to false (unchecked)
        )
    );
    const handleCheckboxChange = (typeIndex: any, yearIndex: any) => {
        const updatedState = checkedState.map((row, t) =>
            row.map((checked, y) =>
                t === typeIndex && y === yearIndex ? !checked : checked
            )
        );
        setCheckedState(updatedState);
    };

    const handleDatahubClean = async () => {
        setDatahubCleaned(false);
        apiClient.get("/natcoData/cleanDatahub")
            .then((response) => {
                setDatahubCleaned(true);
                console.log("response clean datahub", response.data.records);
                setAlert({
                    severity: 'success',
                    message: 'Datahub successfully cleaned',
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const handleSave = async () => {
        setSaveClicked(false);
        // Prepare the data based on the checked boxes
        const dataToSend: any = [];
        console.log(">>>checkedState", checkedState, "isALl", isAll, "natcoRules", natcoRules);
        // if(isAll){
        //     checkedState.forEach((row, typeIndex) => {
        //         row.forEach((checked, yearIndex) => {

        //             if (checked) {
        //                 const productDataType = productDataTypes[typeIndex]; // Assuming each type has an `id` field
        //                 const natco = null; // Define how to get `natcoId`
        //                 const year = years[yearIndex];

        //                 // Push to dataToSend array
        //                 dataToSend.push({ productDataType, natco, year });
        //             }
        //         });
        //     });
        // }else{
        //     checkedState.forEach((row, typeIndex) => {
        //         row.forEach((checked, yearIndex) => {
        //             if (checked) {
        //                 const productDataType = productDataTypes[typeIndex]; // Assuming each type has an `id` field
        //                 const year = years[yearIndex];

        //                 // Loop through all natcos
        //                 natcoRules.forEach(natco => {
        //                     // Push to dataToSend array for each natco
        //                     dataToSend.push({ productDataType, natco, year });
        //                 });
        //             }
        //         });
        //     });

        // }
        let rulesToDelete = '';
        const uniqueIds = new Set(); // Use a Set to automatically handle duplicates

        checkedState.forEach((row, typeIndex) => {
            row.forEach((checked, yearIndex) => {
                if (checked) {
                    const productDataType = productDataTypes[typeIndex]; // Assuming each type has an `id` field
                    const year = years[yearIndex];

                    // Loop through all natcos
                    natcoRules.forEach((natco: any) => {
                        if (!uniqueIds.has(natco.id)) {
                            uniqueIds.add(natco.id); // Add ID to the Set
                        }
                        // Push to dataToSend array for each natco
                        dataToSend.push({ productDataType, natco, year });
                    });
                }
            });
        });

        // Convert unique IDs in the Set to a comma-separated string
        rulesToDelete = Array.from(uniqueIds).join(',');

        console.log("dataToSend", dataToSend);
        console.log("rulesToDelete", rulesToDelete);

        //Delete existing rules
        apiClient
            .delete(`/productDataRule/${rulesToDelete}`)
            .then(() => {
                //Add the post to save rules
                apiClient
                    .post(`/productDataRule`, dataToSend)
                    .then((res: any) => {
                        setSaveClicked(true);
                        setAlert({
                            severity: 'success',
                            message: 'Rules successfully inserted',
                        });
                        console.log("Product data rules are successfully inserted")
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            })
            .catch((error) => {
                console.log(error);
            })


    }


    const handleChange = (event: any) => {
        setSelectedNatco(event.target.value);
        console.log('Selected Value:', event.target.value);
    };

    return (<>
        <Stack spacing={2} sx={{ m: 2 }}>
            {!profile?.isCustomer &&
                <Accordion
                    expanded={expanded === section.rules}
                    onChange={handleChangePanel(section.rules)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Define Rules</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RecordFilterSelect
                            source="natcos"
                            property={'name'}
                            label="Choose Natco"
                            selected={selectedFiltersEdit?.natcos || []} // Ensure selected is always an array
                            onRecordChange={handleRecordChangeEdit}
                            onFilterApply={handleFilterApplyEdit}
                            onFilterCancel={handleFilterCancelEdit}
                            displaySelectedNumber={false}
                            forForecastDeliveries={true}
                        />
                        <br />
                        {natcoRules.length > 0 && (
                            <>
                                <Typography variant="h6" style={{ display: 'inline-block', marginRight: '8px' }}>
                                    Chosen Natco:
                                </Typography>
                                {!isAll && (
                                    <Typography variant="body2" style={{ display: 'inline-block' }}>
                                        {natcoRules.map((n: any) => n.name).join(' - ')}
                                    </Typography>
                                )}
                                {isAll && (
                                    <Typography variant="body2" style={{ display: 'inline-block' }}>
                                        ALL
                                    </Typography>
                                )}
                            </>
                        )}

                        <br />
                        <br />
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        {years.map((year, index) => (
                                            <TableCell key={index} align="center">
                                                {year}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {types.length > 0 && types.map((type: any, typeIndex: any) => (
                                        <TableRow key={typeIndex}>
                                            <TableCell>{type}</TableCell>
                                            {years.map((_, yearIndex) => (
                                                <TableCell key={yearIndex} align="center">
                                                    <Checkbox
                                                        checked={checkedState[typeIndex][yearIndex]}
                                                        onChange={() => handleCheckboxChange(typeIndex, yearIndex)}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        {natcoRules.length > 0 && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </div>}
                        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

                    </AccordionDetails>
                </Accordion>
            }
            {!profile?.isCustomer &&
                <>
                    <Accordion
                        expanded={expanded === section.global}
                        onChange={handleChangePanel(section.global)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Upload Global Data</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Stack
                                spacing={2}
                                // direction="column"
                                alignItems="flex"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<FileDownloadIcon />}
                                    sx={{ textTransform: 'none' }}
                                    onClick={handleDownload} // Call handleDownload on click
                                >
                                    Download existing data
                                </Button>
                                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                    Please upload the updated file
                                </Typography>
                                <UploadForm
                                    pathId={13}
                                    path="natcoData/upload"
                                    subPath='Global'
                                    inputId="natcoData-sheet1"
                                    inputName="natcoData"
                                    inputAccept=".xlsx"
                                />
                                <DataGrid
                                    // onSelectionModelChange={(ids: any) => {
                                    //   setSelectedIds(ids);
                                    // }}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    autoHeight
                                    rows={rowsGlobal}
                                    columns={columnsGlobal}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    processRowUpdate={processRowUpdate}
                                    experimentalFeatures={{ newEditingApi: true }}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded === section.natco}
                        onChange={handleChangePanel(section.natco)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Upload Data By Natco</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                spacing={2}
                                // direction="column"
                                alignItems="flex"
                            >
                                {/* sx={{
                                        'marginTop': -9,
                                        'marginLeft': -12
                                    }} */}
                                <FormControl fullWidth error>
                                    <InputLabel id="dropdown-label" sx={{
                                        '& .MuiInputLabel-animated': {
                                            marginTop: -9,
                                            marginLeft: -12
                                        }

                                    }}>Select a Natco</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="dropdown"
                                        value={selectedNatco}
                                        onChange={handleChange}
                                    >
                                        {records.map((record: any) => (
                                            <MenuItem key={record.id} value={record.name}>
                                                {record.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {selectedNatco &&
                                    <>
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                            Selected Natco: {selectedNatco}
                                        </Typography>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<FileDownloadIcon />}
                                            sx={{ textTransform: 'none' }}
                                            onClick={handleDownload} // Call handleDownload on click
                                        >
                                            Download existing data
                                        </Button>

                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                            Please upload the updated file
                                        </Typography>

                                        <UploadForm
                                            pathId={13}
                                            path="natcoData/upload"
                                            subPath={selectedNatco}
                                            inputId="natcoData-sheet"
                                            inputName="natcoData"
                                            inputAccept=".xlsx"
                                        /></>}

                            </Stack>
                            {selectedNatco &&
                                <>
                                    <DataGrid
                                        // onSelectionModelChange={(ids: any) => {
                                        //   setSelectedIds(ids);
                                        // }}
                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                        autoHeight
                                        rows={rows}
                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        processRowUpdate={processRowUpdate}
                                        experimentalFeatures={{ newEditingApi: true }}
                                    />
                                </>
                            }

                        </AccordionDetails>
                    </Accordion>
                </>
            }

            {profile?.isCustomer &&
                <Guard role={Role.ForecastDeliveriesUser}>

                    <Stack
                        spacing={2}
                        // direction="column"
                        alignItems="flex"
                    >

                        <>


                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<FileDownloadIcon />}
                                sx={{ textTransform: 'none' }}
                                onClick={handleDownload} // Call handleDownload on click
                            >
                                Download existing data
                            </Button>

                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                Please upload the updated file
                            </Typography>

                            <UploadForm
                                pathId={13}
                                path="natcoData/upload"
                                subPath={selectedNatco}
                                inputId="natcoData-sheet"
                                inputName="natcoData"
                                inputAccept=".xlsx"
                            /></>

                    </Stack>
                    <>
                        <DataGrid
                            // onSelectionModelChange={(ids: any) => {
                            //   setSelectedIds(ids);
                            // }}
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            autoHeight
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            disableSelectionOnClick
                            processRowUpdate={processRowUpdate}
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </>
                </Guard>
            }

            {!profile?.isCustomer &&
                <Accordion
                    expanded={expanded === section.datahub}
                    onChange={handleChangePanel(section.datahub)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>DataHub interactions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDatahubClean}
                        >
                            Clean dataHub
                        </Button>
                        <br />
                        <br />
                        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

                        <DataGrid
                            // onSelectionModelChange={(ids: any) => {
                            //   setSelectedIds(ids);
                            // }}
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            getRowId={(row) => row.ProductId}
                            autoHeight
                            rows={rowsDataHubProducts}
                            columns={columnsDataHubProducts}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            disableSelectionOnClick
                        // processRowUpdate={processRowUpdate}
                        // experimentalFeatures={{ newEditingApi: true }}
                        />
                        <br />
                        <DataGrid
                            // onSelectionModelChange={(ids: any) => {
                            //   setSelectedIds(ids);
                            // }}
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            getRowId={(row) => row.QuantityId}
                            autoHeight
                            rows={rowsDatahubQuantities}
                            columns={columnsDataHubQuantities}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            disableSelectionOnClick
                        // processRowUpdate={processRowUpdate}
                        // experimentalFeatures={{ newEditingApi: true }}
                        />

                    </AccordionDetails>
                </Accordion>

            }
            <ConfirmDialog
                title="Confirm Value Update"
                open={openConfirm}
                setOpen={setOpenConfirm}
                onConfirm={handleConfirmUpdate}
                onCancel={handleCancelUpdate}
            >
                <Typography>Are you sure to update this value?</Typography>
            </ConfirmDialog>

            <>

            </>
            <br />

        </Stack>

    </>)
}