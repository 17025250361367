import { Button, ButtonGroup, Divider, MenuItem, Select, Stack } from "@mui/material";
import { useState } from "react";
import EntityData from "./EntityData";
import InputData from "./InputData";

export default function FillData() {

    const [configView, setConfigView] = useState(true);


    return (<>  
    {/* <ButtonGroup sx={{ m: 2 }}>
        <Button
            variant={configView ? 'contained' : 'outlined'}
            onClick={() => setConfigView(true)}
        >
            Entity Data
        </Button>
        <Button
            variant={configView ? 'outlined' : 'contained'}
            onClick={() => setConfigView(false)}
        >
            Input Data
        </Button>
    </ButtonGroup> */}
        {/* <Divider /> */}
        {/* {configView && <>
            <EntityData></EntityData>
        </>
        }

        {!configView && <>
            
        </>
        } */}
<InputData></InputData>

    </>

    )
}