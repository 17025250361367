import { useState } from 'react';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { nfdSearch } from '../../../../utils';

export default function FilterDialog({
  open,
  handleFilterCancel,
  label,
  selected,
  loading,
  records,
  handleRecordChange,
  handleFilterApply,
  isProductCatalog,
  handleFilterClear,
  forForecastDeliveries = false,
}: any) {
  const [search, setSearch] = useState('');

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const handleSelectAll = () => {
    const filteredRecords = records.filter((record: any) => nfdSearch(record.name, search));
    const newSelections = [
      ...(Array.isArray(selected) ? selected : []), // Ensure selected is an array
      ...filteredRecords.filter(
        (record: any) => !selected.some((item: any) => item.id === record.id)
      ),
    ];
    handleRecordChange('natcos', newSelections, true); // Replace all
  };

  const handleDeselectAll = () => {
    const filteredRecords = records.filter((record: any) => nfdSearch(record.name, search));
    const remainingSelections = (Array.isArray(selected) ? selected : []).filter(
      (item: any) => !filteredRecords.some((record: any) => record.id === item.id)
    );
    handleRecordChange('natcos', remainingSelections, true); // Replace all
  };



  return (
    <Dialog open={open} onClose={handleFilterCancel} maxWidth="md" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            Select {label} {Boolean(selected.length) && `(${selected.length})`}
          </span>
          <div style={{ display: 'flex', gap: '10px' }}>
            {forForecastDeliveries && (
              <>
                <Button variant="outlined" size="small" onClick={handleSelectAll}>
                  Select All
                </Button>
                <Button variant="outlined" size="small" onClick={handleDeselectAll}>
                  Deselect All
                </Button>
              </>
            )}
            <TextField
              variant="outlined"
              value={search}
              size="small"
              onChange={handleSearch}
              placeholder="Search..."
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div style={{ alignItems: 'center' }}>
            <CircularProgress />
          </div>
        )}
        <Grid container spacing={0}>
          {records
            .filter((r: any) => nfdSearch(r.name, search))
            .map((record: any, index: any) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={forForecastDeliveries ? selected.some((item: any) => item.id === record.id) : selected.filter((item: any) => {
                        return item.id === record.id;
                      }).length > 0}
                      onChange={(event, checked) =>
                        handleRecordChange(checked, record)
                      }
                    />
                  }
                  label={record.name}
                />
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isProductCatalog && (
          <Button onClick={handleFilterClear} startIcon={<RotateLeftIcon />}>
            Clear
          </Button>
        )}
        <Button onClick={handleFilterCancel}>Cancel</Button>
        <Button
          onClick={handleFilterApply}
          variant="contained"
          color="primary"
          autoFocus
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
