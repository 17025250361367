import { WebStorageStateStore } from 'oidc-client';


export const authSettings = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${window.location.origin}/`,
  response_type: 'code',
  scope: 'openid profile email',
  loadUserInfo: true,
  userStore: new WebStorageStateStore({
    prefix : `${process.env.REACT_APP_OIDC_PREFIX}.`,
    store: window.sessionStorage
  }),
};

type statusType = {
  value: number;
  name: string;
  color:
    | 'error'
    | 'success'
    | 'secondary'
    | 'warning'
    | 'info'
    | 'default'
    | 'primary'
    | undefined;
};

type reportType = {
  value: boolean;
  name: string;
};

export const STATUSES: statusType[] = [
  { value: 0, name: 'Closed', color: 'error' },
  { value: 1, name: 'Active', color: 'success' },
  { value: 2, name: 'Expiring soon', color: 'secondary' },
  { value: 3, name: 'Preparation', color: 'warning' },
  { value: 4, name: 'Terminated', color: 'info' },
];

export const PRODUCT_CATALOG_STATUSES: statusType[] = [
  { value: 0, name: 'Archived', color: 'error' },
  { value: 1, name: 'Active', color: 'success' },
];

export const REPORT_TYPES: reportType[] = [
  { value: true, name: 'Paginated' },
  { value: false, name: 'Power BI' },
];

export const NEGOTIATION_STATUSES: statusType[] = [
  { value: 0, name: 'Planned', color: 'warning' },
  { value: 1, name: 'Started', color: 'success' },
  { value: 2, name: 'Cancelled', color: 'error' },
  { value: 3, name: 'Closed', color: undefined },
  { value: 4, name: 'Already negotiated', color: 'info' },
  { value: 5, name: 'Approved by central controller', color: 'info' },
  { value: 6, name: 'Approved by local controller', color: 'info' },
  { value: 7, name: 'Awaiting info from NatCo', color: 'info' },
  { value: 8, name: 'Deal lost', color: 'info' },
  { value: 9, name: 'On hold', color: 'info' },
  { value: 10, name: 'Qualified', color: 'info' },
  { value: 11, name: 'Secured', color: 'info' },
  { value: 11, name: 'Draft', color: 'info' },
];

export enum Role {
  User = 'ROLE_USER',
  ReportUser = 'ROLE_REPORT_USER',
  ProductCatalogUser = 'ROLE_PRODUCT_CATALOG_USER',
  ForecastDeliveriesUser = 'ROLE_FORECAST_DELIVERIES_USER',
  TestToolsUser = 'ROLE_TEST_TOOLS',
  Admin = 'ROLE_ADMIN',
  Manager = 'ROLE_MANAGER',
  ContactManager = 'ROLE_CONTACT_MANAGER',
  SupplierManager = 'ROLE_SUPPLIER_MANAGER',
  ContractManager = 'ROLE_CONTRACT_MANAGER',
  NegotiationManager = 'ROLE_NEGOTIATION_MANAGER',
  PublicNewsManager = 'ROLE_PUBLIC_NEWS_MANAGER',
  SummitManager = 'ROLE_SUMMIT_MANAGER',
  ToolsManager = 'ROLE_TOOLS_MANAGER',
  ProductCatalogManager = 'ROLE_PRODUCT_CATALOG_MANAGER',
  BenchmarkManager = 'ROLE_BENCHMARK_MANAGER',
  DealOptimizerManager = 'ROLE_DEAL_OPTIMIZER_MANAGER',
  ProductManager = 'ROLE_PRODUCT_MANAGER',
  ConfiguratorManager = 'ROLE_CONFIGURATOR_MANAGER',
  PowerBIManager = 'ROLE_POWER_BI_MANAGER',
  ToolsRolesManager = 'ROLE_TOOLS_ROLE_MANAGER',
  ReferentialManager = 'ROLE_REFERENTIAL_MANAGER',
  SustainabilityChatbotManager = 'ROLE_SUSTAINABILITY_CHATBOT_MANAGER',
  MBN_CHATBOT_MANAGER='ROLE_MBN_CHATBOT_MANAGER',
  ForecastDeliveriesManager = 'ROLE_FORECAST_DELIVERIES_MANAGER',
}

export const ROLE_NAMES: any = {
  [Role.Admin]: 'Admin',
  [Role.ProductCatalogManager]: 'Product Catalog Manager',
  [Role.BenchmarkManager]: 'Benchmark Manager',
  [Role.ConfiguratorManager]: 'Configurator Manager',
  [Role.ContactManager]: 'Contacts Manager',
  [Role.ContractManager]: 'Contracts Manager',
  [Role.DealOptimizerManager]: 'Deal optmimizer Manager',
  [Role.NegotiationManager]: 'Negotiations Manager',
  [Role.PowerBIManager]: 'PowerBI Manager',
  [Role.ProductManager]: 'Product Manager',
  [Role.PublicNewsManager]: 'Public news Manager',
  [Role.ReferentialManager]: 'Referential Manager',
  [Role.SustainabilityChatbotManager]: 'Sustainability Chatbot Manager',
  [Role.ReportUser]: 'Report User',
  [Role.SummitManager]: 'Summit Manager',
  [Role.SupplierManager]: 'Suppliers Manager',
  [Role.ToolsManager]: 'Tools Manager',
  [Role.ToolsRolesManager]: 'Tools roles Manager',
  [Role.ProductCatalogUser]: 'Product Catalog User',
  [Role.TestToolsUser]: 'Test Tools User',
  [Role.ForecastDeliveriesUser]: 'Forecast deliveries User',
  [Role.MBN_CHATBOT_MANAGER]: 'MBN Chatbot Manager',
  [Role.ForecastDeliveriesManager]: 'Forecast deliveries Manager',
};

export const ROLE_HIERARCHY: any = {
  [Role.Admin]: [
    Role.Manager,
    Role.ContactManager,
    Role.ContractManager,
    Role.NegotiationManager,
    Role.SupplierManager,
    Role.SummitManager,
    Role.BenchmarkManager,
    Role.DealOptimizerManager,
    Role.ProductManager,
    Role.ConfiguratorManager,
    Role.ToolsManager,
    Role.PublicNewsManager,
    Role.ReferentialManager,
    Role.ToolsRolesManager,
    Role.ProductCatalogManager,
    Role.PowerBIManager,
    Role.SustainabilityChatbotManager,
    Role.ReportUser,
    Role.TestToolsUser,
    Role.ForecastDeliveriesUser,
    Role.MBN_CHATBOT_MANAGER,
    Role.ForecastDeliveriesManager,
  ],

  [Role.ReferentialManager]: [
    Role.ContactManager,
    Role.ContractManager,
    Role.Manager,
    Role.NegotiationManager,
    Role.PublicNewsManager,
    Role.SupplierManager,
  ],

  [Role.ToolsManager]: [
    Role.ProductCatalogManager,
    Role.BenchmarkManager,
    Role.ConfiguratorManager,
    Role.DealOptimizerManager,
    Role.Manager,
    Role.PowerBIManager,
    Role.ProductManager,
    Role.ToolsRolesManager,
    Role.SustainabilityChatbotManager,
  ],

  [Role.ContactManager]: [Role.Manager, Role.ReferentialManager],
  [Role.ContractManager]: [Role.Manager, Role.ReferentialManager],
  [Role.NegotiationManager]: [Role.Manager, Role.ReferentialManager],
  [Role.SupplierManager]: [Role.Manager, Role.ReferentialManager],
  [Role.PublicNewsManager]: [Role.Manager, Role.ReferentialManager],

  // [Role.ForecastDeliveriesUser]: [Role.ForecastDeliveriesManager],

  [Role.SummitManager]: [Role.Manager],
  [Role.BenchmarkManager]: [Role.Manager],
  [Role.DealOptimizerManager]: [Role.Manager],
  [Role.ConfiguratorManager]: [Role.Manager],
  [Role.ProductManager]: [Role.Manager],
  [Role.PowerBIManager]: [Role.Manager],
  [Role.ToolsRolesManager]: [Role.Manager],
  [Role.ProductCatalogManager]: [Role.Manager],
  [Role.SustainabilityChatbotManager]: [Role.Manager],
  [Role.ProductCatalogUser]: [Role.ProductCatalogManager],
  [Role.MBN_CHATBOT_MANAGER]: [Role.Manager],
  [Role.ForecastDeliveriesManager]: [Role.Manager],
};

type AdminMenu = {
  name: string;
  label: string;
  route: string;
  role: string;
};

export const ADMIN_MENUS: AdminMenu[] = [
  {
    name: 'users',
    label: 'User management',
    route: '/admin/users',
    role: Role.Admin,
  },
  {
    name: 'toolsRoles',
    label: 'Tools roles management',
    route: '/admin/toolsRoles',
    role: Role.ToolsRolesManager,
  },
  {
    name: 'upload',
    label: 'Data upload',
    route: '/admin/upload',
    role: Role.ReferentialManager,
  },
  {
    name: 'summit',
    label: 'Digital summit',
    route: '/admin/summit',
    role: Role.SummitManager,
  },
  {
    name: 'powerBi',
    label: 'Power BI',
    route: '/admin/powerBi',
    role: Role.PowerBIManager,
  },
  {
    name: 'productCatalog',
    label: 'Product Catalog',
    route: '/admin/productCatalog',
    role: Role.ProductCatalogManager,
  },
  {
    name: 'spend-financials',
    label: 'Spend & financials',
    route: '/admin/spend-financials',
    role: Role.PowerBIManager,
  },
  {
    name: 'mbn-chatbot',
    label: 'Chatbot Management',
    route: '/admin/mbn-chatbot',
    role: Role.MBN_CHATBOT_MANAGER,
  },
  // {
  //   name: 'forecast-deliveries',
  //   label: 'Forecast & Deliveries',
  //   route: '/admin/forecast-deliveries',
  //   role: Role.ForecastDeliveriesUser,
  // },
];

export const NATCO_BUYIN_POWERBI_PARAMETER = 'OFR';

export const FILTER_TYPES_PRODUCT_CATALOG = [
  {
    name: 'TEXT',
    value: 0,
  },
  {
    name: 'DROPDOWN',
    value: 1,
  },
  {
    name: 'SLIDER',
    value: 2,
  },
];

export const PRICE_TYPES_PRODUCT_CATALOG = [
  {
    name: 'Most Recent Price',
    value: 0,
  },
  {
    name: 'Minimum Price',
    value: 1,
  },
];


